import HomeA from './Views/Home-A.vue';
import HomeB from './Views/Home-B.vue';
import ThankYou from './Views/ThankYou.vue';
import PrivacyPolicy from './Views/Policy/PrivacyPolicy';
import CookiePolicy from './Views/Policy/CookiePolicy';
import ComplaintPolicy from './Views/Policy/ComplaintsPolicy';
import TermsAndConditions from "./Views/Policy/TermsAndConditions";

const root = "";

export const routes = [
    {
        name: "HomeA",
        path: "/",
        component: HomeA
    },
    {
        name: "HomeB",
        path: "/quote",
        component: HomeB
    },
    // *** ********************** *//
    // *** Normal Pages           *//
    // *** ********************** *//

    {
        name: "Thank You",
        path: `${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Cookies",
        path: `${root}/cookies`,
        component: CookiePolicy
    },
    {
        name: "Privacy",
        path: `${root}/privacy`,
        component: PrivacyPolicy
    },
    {
        name: "Terms",
        path: `${root}/terms`,
        component: TermsAndConditions
    },
    {
        name: "Complaints",
        path: `${root}/complaints`,
        component: ComplaintPolicy
    }
]
