<template>
    <div :class="`call-to-action container-fluid ${swap ? `background` : ``}`">
          <div class="row">
            <div :class="`
            pt-4 mt-2
            pb-0 mb-0
            pt-md-5 mt-md-5
            pb-md-5 mb-md-5
            order-0
            col-12 offset-0
            col-md-6
            ${swap ? `order-md-1 offset-lg-1` : ``}`">
              <Section
                :title="title"
                :leading="leading"
                :points="points"
                bullet="circle"
                >
                <slot></slot>
              </Section>
            </div>
            <div :class="`background-column flex-column justify-content-between order-1
            col-12 offset-0
            col-sm-12 offset-sm-0
            col-md-6 offset-md-0
            col-lg-5 offset-lg-0
            ${swap ? `order-md-0` : `offset-lg-1`}
            `"
                 :style="{ backgroundImage: `url(${require(`@/assets/sections/${image}`)})` }"
            >
              <LendingCounter class="lending-counter" v-if="counter"  />
            </div>
          </div>

    </div>
</template>

<script>
import LendingCounter from "../components/LendingCounter";
import Section from "../components/Section";

export default {
  name: "InfoSectionBackground",
  components: {
    Section,
    LendingCounter,
  },
  props: {
    title: {
      type:String,
      required: true
    },
    leading: {
      type:String,
      required: false
    },
    image: {
      default: "car",
      type: String
    },
    points: {
      default: () => [],
      type: Array
    },
    swap: {
      default: false,
      type: Boolean
    },
    counter: {
      default: false,
      type: Boolean
    },
  },
  methods: {
      scrollTop() {
          window.scrollTo(0, 0);
      }
  }
}
</script>

<style lang="scss" scoped>

.call-to-action {
  color:#17174C;
}

.call-to-action.background {
  background-color: rgba(242,197,10,0.1);
}

.call-to-action .background-column {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.call-to-action .leading {
  font-size:17px;
  line-height:35px;
  font-weight: 600;
}

.call-to-action .lending-counter {
  position: absolute;
  bottom: 0;
  left:1rem;
  z-index: 100;
  float: left;
  margin-left:0;
}

/* less than 768px */
@media (max-width: 767.98px) {
  .call-to-action .background-column {
    background-position: center top;
    width:100%;
    height:auto;
    min-height: 500px;
    padding:0;
    margin:0;
  }
}

</style>