<template>
    <div ref="appContainer">
      <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
      <Hero
          background-class="people text-white"
          image="just-gradient"
          title="Simple loans in minutes."
          leading="Specialised loan products for homeowners based on people, not just credit scores."
          :points="[
            '£3K - £100K loans',
            'Our bespoke technology makes everything easy',
            'Our friendly, expert team supports you all the way',
            'Access to exclusive offers',
            'Quick decisions / fast payouts'
            ]"
      />
      <TrustPilot />
      <InfoSectionBackground
          image="people.jpg"
          title="Quick and easy loans."
          leading="We make it quick and easy to find the right loan, and our application process is straightforward and simple."
          :points="[
            'Loans from £3K - £100K',
            'No Up Front Fees',
            'Access To Exclusive Offers',
            'Fast payouts',
            '5 Star customer service awarded by our customers',
            'Bad debt / bad credit profiles considered'
          ]"
          :counter="true"
          :swap="true"
      />
      <InfoSection
          image="searching"
          title="We work hard - you get the best deal."
      >
        <p class="mt-3">Because we’re a credit broker, not a lender, we’re able to search a panel
          of leading lenders to find the loan that suits you best.</p>
        <p>Got a less-than-perfect credit history? No need to worry.</p>
        <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
      </InfoSection>
      <HowItWorks/>
      <Contact />
      <Lenders />
      <Footer />
    </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import InfoSectionBackground from "../Sections/InfoSectionBackground.vue";

export default {
  name: 'app',
  components: {
    InfoSectionBackground,
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Lenders,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
          iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
