var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"image":"at-laptop","title":"Simple loans in minutes.","leading":"Specialised loan products for homeowners based on people, not just credit scores.","points":[
        '£3K - £100K loans',
        'Our bespoke technology makes everything easy',
        'Our friendly, expert team supports you all the way',
        'Access to exclusive offers',
        'Quick decisions / fast payouts'
        ]}}),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"wedding","title":"Quick and easy loans.","leading":"We make it quick and easy to find the right loan, and our application process is straightforward and simple.","points":[
        'Loans from £3K - £100K',
        'No Up Front Fees',
        'Access To Exclusive Offers',
        'Fast payouts',
        '5 Star customer service awarded by our customers',
        'Bad debt / bad credit profiles considered'
      ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching","title":"We work hard - you get the best deal."}},[_c('p',{staticClass:"mt-3"},[_vm._v("Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the loan that suits you best.")]),_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")])]),_c('HowItWorks'),_c('Contact'),_c('Lenders'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }