<template>
  <div class="how-it-works pt-4 pb-4">
    <div class="container-fluid">
      <div class="container text-center">
        <h2 class="title">How it works</h2>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <img src="@/assets/how-it-works/step-1.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 1.</h3>
            <p>Simple, easy application</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <img src="@/assets/how-it-works/step-2.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 2.</h3>
            <p>We search our panel of lenders to find the deal that’s right for you</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <img src="@/assets/how-it-works/step-3.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 3.</h3>
            <p>E-sign your agreement and receive your funds</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "HowItWorks",
      methods: {
        scrollTop() {
          window.scrollTo(0, 0);
        }
      }
    }
</script>

<style lang="scss" scoped>

.how-it-works {
  background-color: rgba(242,197,10,0.1);
  color: #17174C;
  h3 {
    font-size:1.25rem;
  }
  p {
    font-size:1rem;
  }
  button {
    font-size: 1.2rem;
    border-radius: 2rem;
    padding: 1rem 2rem;
  }
  button.more {
    color:white;
    background-color: #17174C;
  }
  .click-tech {
    animation: ease-in-out;
    transition: display;
  }
}

</style>
