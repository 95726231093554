
export const branding = {
    name: "Believe Loans Ltd",
    shortName: "Believe Loans",
    website: {
        url: "https://www.believeloans.com",
        shortUrl: "believeloans.com",
        description: "www.believeloans.co.uk"
    },
    phone: "01302 591 320",
    address: "Believe House, Heavens Walk, Doncaster, DN4 5HZ",
    openingHours: ["Monday - Thursday 9am - 7pm", "Friday 9am - 3pm"],
    trustPilot: {
        businessUnitId: "59a6e7ff0000ff0005aa092e"
    },
    registration: {
        fca: {
            number: "786476",
            url: "https://register.fca.org.uk/s/firm?id=001b000003wPyD1AAK"
        },
        company: {
            number: "10712649",
            url: "https://beta.companieshouse.gov.uk/company/10712649"
        },
        dataprotection: {
            number: "ZA272202",
            url: "https://ico.org.uk/ESDWebPages/Entry/ZA272202"
        }
    },
    groupName: "Believe Money Group",
    group: "Believe Finance, Believe Specialist Finance, Believe Loans, Believe Protect and Simply Money part of the Believe Money Group",
    appointed: {
        name: "Believe Advisor Limited",
        address: "Believe House, Heavens Walk, Doncaster, DN4 5HZ",
        phone: "01302 238 209",
        compliance: {
            email: "compliance@believeadvisor.com",
            phone: "01302 591 293"
        },
        registration: {
            fca: {
                number: "841395",
                url: "https://register.fca.org.uk/s/firm?id=0010X00004T6W1yQAF"
            },
            company: {
                number: "11842506",
                url: "https://beta.companieshouse.gov.uk/company/11842506"
            }
        }
    }
}
