<template>
  <div :class="`hero-area pb-0 pb-sm-4 ${backgroundClass} ${displayAppForm ? 'form-active': ''}`">
    <div class="container" :class="{ 'container-fluid' : displayAppForm }">
      <AppForm
          :homeOwnerMode="homeOwnerMode"
          :hybridMode="hybridMode"
          class="hero-area__app-form"
          v-if="displayAppForm" />
      <div class="hero-area__app-form hero-area__app-form-selector" v-else-if="displayAppFormSelector">
        <div class="row">
          <div class="col12 col-md-6">
            <h1 class="title">Are you a homeowner or a tenant?</h1>
          </div>
          <div class="col-12 col-md-4 offset-md-1 hero-area__app-form-selector__buttons">
            <button :class="`btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton my-3 px-5 py-3 ${ buttonClass }`"
                    type="button"
                    name="button"
                    @click="showAppForm('Homeowner')"
            >I am a homeowner
            </button>

            <button :class="`btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton my-3 px-5 py-3 ${ buttonClass }`"
                    type="button"
                    name="button"
                    @click="showAppForm('Tenant')"
            >I am a tenant
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row pt-3 pt-sm-5 hero-area__content__mobile d-md-none">
          <div class="col">
            <Section
                :parent-class="`hero ${backgroundClass}`"
                :title="title"
                :leading="leading"
                :points="points"
                bullet="tick">
              <template slot="fold">
                <slot></slot>
                <div class="hero-area__apply__mobile h-100" :class="backgroundClass">
                  <apply-router @showAppForm="showAppForm" />
                </div>
              </template>
            </Section>
          </div>
        </div>
        <div class="row pt-3 pt-sm-5 hero-area__content d-none d-md-flex">
          <div class="col-12 col-md-6">
            <Section
                :parent-class="backgroundClass"
              :title="title"
              :leading="leading"
              :points="points"
              bullet="tick"
            />
          </div>
          <div
              class="col-12 col-md-6 hero-area__background"
          >
            <div class="hero-area__apply p-2 h-100">
              <apply-router @showAppForm="showAppForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tenant-popup" :class="{ open: tenantWarning }">
      <div>
        <h2>Sorry</h2>
        <p>We can only source loans for homeowners at the moment.</p>
        <p>
          <button class="btn btn-xl" @click="tenantWarning = false">OK</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AppForm from '../components/AppForm'
import {Bus} from '../bus'
import ApplyRouter from "../components/Apply/Apply";
import Section from "../components/Section";

    export default {
      components: {
        ApplyRouter,
        AppForm,
        Section
      },
      props: {
        title: {
          type:String,
          required: true
        },
        leading: {
          type:String,
          required: true
        },
        points: {
          default: () => [],
          type: Array
        },
        image: {
          default: "summer",
          type: String
        },
        backgroundClass: {
          type: String
        },
        disableTenant: {
          default: false,
          type: Boolean
        }
      },
      created() {
        Bus.$on("headerLogoClicked", () => {
          this.hideAppForm();
        });
      },
      data() {
        return {
          displayAppForm: false,
          displayAppFormSelector:false,
          homeOwnerMode: true,
          hybridMode: false,
          tenantWarning: false
        }
      },
      computed: {
        buttonClass() {
          if (this.$route.name === "DebtConsolidation") {
            return "green"
          } else if (this.$route.name === "Home") {
            return "blue"
          }
          return "red";
        }
      },
      methods: {
        showAppForm(form) {
          // Reset
          this.hybridMode = false;
          this.homeOwnerMode = false;
          // Select
          switch (form) {
            case 'Hybrid':
              this.hybridMode = true;
              break;
            case 'Homeowner':
              this.homeOwnerMode = true;
              break;
            case 'Tenant':
              if (this.disableTenant) {
                this.tenantWarning = true;
                return;
              } else {
                this.homeOwnerMode = false;
              }
              break;
          }
          if (form !== '') {
            this.displayAppForm = true;
          }
        },
        hideAppForm() {
          this.displayAppForm = false;
          this.displayAppFormSelector = false;
        }
      }
    }
</script>

<style scoped type="scss">

  .container--full-width {
    margin: 0;
    padding: 0 0 3rem;
    max-width: 100%;
  }

  .people.form-active {
    background-image: none !important;
  }

  .container.container-fluid {
    max-width: 100%;
    padding: 0 0 3rem;
  }


  .hero-area__app-form-selector {
    padding: 4rem 0;
  }


  /* ------------------------------------------------------------------------------- */
  /* Tenant Pop up */
  /* ------------------------------------------------------------------------------- */

  .tenant-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9990;
    align-items: center;
    justify-content: center;
    color:black;
  }

  .tenant-popup div {
    margin: 1em;
    background: white;
    padding: 2em;
    border-radius: 0.5em;
  }

  .tenant-popup button {
    padding: 10px;
    width: 100%;
  }

  .tenant-popup.open {
    display: flex;
  }

  /* ------------------------------------------------------------------------------- */
  /* Cta Buttons */
  /* ------------------------------------------------------------------------------- */
  .ctaButton.green {
    background-color: #56B27F;
    border-color: #56B27F;
  &:active,
  &:focus,
  &:active:focus {
     background-color: #56B27F !important;
     border-color: #56B27F !important;
     box-shadow: 0 0 0 0.2rem rgba(86, 178, 127, 0.5) !important;
   }
  }

  .ctaButton.blue {
    background-color: #17174C;
    border-color: #17174C;
  &:active,
  &:focus,
  &:active:focus {
     background-color: #17174C !important;
     border-color: #17174C !important;
     box-shadow: 0 0 0 0.2rem rgba(23, 23, 76, 0.5) !important;
   }
  }

  .ctaButton.red {
    background-color: #dd1d42;
    border-color: #dd1d42;
  &:active,
  &:focus,
  &:active:focus  {
     background-color: #dd1d42 !important;
     border-color: #dd1d42 !important;
     box-shadow: 0 0 0 0.2rem rgba(221, 129, 66, 0.5) !important;
   }
  }

  /* ------------------------------------------------------------------------------- */
  /* Hero (Desktop) */
  /* ------------------------------------------------------------------------------- */

  .hero-area .hero-area__background {
    position: relative;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  /* ------------------------------------------------------------------------------- */
  /* Hero (Mobile) */
  /* ------------------------------------------------------------------------------- */

  .hero-area .hero-area__apply__mobile {
    position: relative;
    width:100%;
    margin: 0 auto;
  }

  /*.hero-area .hero-area__apply__mobile.people {*/
  /*  background-image: linear-gradient(45deg, #e39530,#e8a933,#efc437);*/
  /*}*/

  .hero-area__apply__mobile__image {
    width: 150px;
    height:auto;
    position: absolute;
    top: -110px;
    right:-10px;
  }

  @media (max-width: 575px){
    .hero-area .hero-area__apply__mobile {
      width:100%;
    }
    .hero-area .hero-area__apply__mobile.people {
      background-image:none;
    }
  }

</style>
