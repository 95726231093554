<template>
  <div>
    <footer id="believeLoansFooter">
      <div class="container">
        <div class="row align-items-center pt-4">
          <div class="col-md-9 col-sm-6 py-2">
            <img src="@/assets/footer-logo-believe-loans.svg" width="138" :alt="branding.shortName">
          </div>
          <div class="col-md-3 col-sm-6 py-2">
            <button @click="scrollTop" class="btn btn-xl btn-secondary ctaButton" type="button" name="button">Request a Quote</button>
          </div>
        </div>
        <div class="row pt-3 hero-button">
          <div class="col-md-3 col-sm-6">
            <h1 class="title">
              Contact Us
            </h1>
            <hr class="footer">
            <p class="copy">
              Call from a mobile or Landline:
              <br><br>
              <template v-for="(item, index) in branding.openingHours">
                {{ item  }}
                <template v-if="index < branding.openingHours.length - 1">
                  <br :key="index"/>
                </template>
              </template>
            </p>
            <hr class="footer">
            <h1 class="title">
              <a :href="`tel:${branding.phone.replaceAll(' ', '')}`">{{ branding.phone }}</a>
            </h1>
            <p class="copy">
              Calls may be recorded for training and monitoring purposes.
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <p class="copy">
              Think carefully before securing debts against your home. Your home may be repossessed if you do not keep up repayments on your Loan or Mortgage.
              <br><br>
              We are a licensed credit broker, not a Lender.
              <br><br>
<!--              Typical Fees can range from a minimum of £495 to a maximum £2995 and is payable upon completion and can be added to your loan. We will receive a commission from the lender in addition to the fees you pay.-->

              We offer Rates from 5.49% to 49% APR and Loan Terms from 3 years to 30 years.<br/><br/>
              We’ve offered secured loans ranging from £5,000 to £500,000.
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <p class="copy">
              The Financial Conduct Authority does not regulate some forms of Buy to Let Mortgages. The guidance and/or advice contained within this website is subject to the UK regulatory regime and is therefore targeted at consumers based in the UK.
              <br><br>
              The amount offered will depend on your personal circumstances and your credit rating.
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <p class="copy">
              Believe Homeowner Experts is trading style of {{ branding.name }} .<br/><br/>
              Registered address: {{ branding.address }}
              <br><br>
              {{ branding.name }} is registered in England and Wales.
              <br><br>
              Company Registration Number: <a :href="branding.registration.company.url" target="_blank">{{ branding.registration.company.number }}</a>.
              <br>
              Data Protection Registration No: <a :href="branding.registration.dataprotection.url" target="_blank">{{ branding.registration.dataprotection.number }}</a>.
              <br>
              {{ branding.shortName }} FCA Number: <a :href="branding.registration.fca.url" target="_blank">{{  branding.registration.fca.number }}</a>.
            </p>
          </div>
            <div class="col-12 offset-md-3 col-md-9">
              <p class="copy">{{ branding.name }}, FCA <a :href="branding.registration.fca.url" target="_blank">{{ branding.registration.fca.number }}</a>, is an Appointed Representative of {{ branding.appointed.name }} which is authorised and regulated by the Financial Conduct Authority under FCA number <a :href="branding.appointed.registration.fca.url" target="_blank">{{ branding.appointed.registration.fca.number }}</a>.</p>
            </div>
          <hr class="footerLong">
        </div>
        <div class="row pt-4 pb-4 hero-button">
          <div class="col-md-7 col-sm-6 col-12 text-center text-md-left">
            <h1 class="copy">
              <strong>Copyright © 2022 {{ branding.name }} All rights reserved.</strong>
            </h1>
          </div>
          <div class="col-md-5 col-sm-6 col-12 text-center text-md-right">
            <h1 class="copy">
    <!--          <router-link to="/complaints">Complaints</router-link>-->
    <!--          | -->
              <router-link to="/cookies">Cookie Policy</router-link>
              | <router-link to="/privacy">Privacy Policy</router-link>
              | <router-link to="/terms">Terms and Conditions</router-link>
            </h1>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { branding } from "@/branding";
export default {
  data() {
    return {
      branding: branding
    };
  },
  methods: {
    scrollTop() {
        window.scrollTo(0, 0);
    },
    scrollToAbout() {
      window.scrollBy({
        top: document.getElementById('anchor__why').getBoundingClientRect().top - 88,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.ctaButton {
  font-size: 1rem;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
}
</style>
