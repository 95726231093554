<template>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <apply-button @selectForm="showAppForm" v-if="useButton"   />
  </div>
</template>

<script>
import ApplyButton from "./Apply-Button";
export default {
  name: "ApplyRouter",
  components: {ApplyButton },
  props: {
    currentLendingAmount: Number
  },
  methods: {
    showAppForm(form) {
      this.$emit('showAppForm', form);
      window.scrollTo(0, 0);
    }
  },
  computed: {
    useButton() {
      return true
    }
  }
}
</script>
