<template>
    <div id="form-1" :class="`apply-button d-flex w-75 h-50 text-center justify-content-center p-0 m-0 ${backgroundClass}`">
      <h5 class="font-weight-bold">
        Get your quick quote
      </h5>
      <button class="btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton m-3 p-3"
              type="button"
              name="button"
              @click="onButtonClicked('Hybrid')"
      >Get a quote</button>
    </div>
</template>

<script>
export default {
  name: "ApplyButton",
  methods: {
    onButtonClicked(form) {
      this.$emit("selectForm", form);
    }
  },
  computed: {
    backgroundClass() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>

.apply-button.HomeA {
  background-color:#17174C;
}
.apply-button.HomeA h5 {
  color:#fff !important;
 }
.apply-button.HomeB {
  background: linear-gradient(280deg, #f2c50a 0%, #e39530 100%);
}
.apply-button.HomeB h5 {
  color:#000 !important;
}

.apply-button {
  flex-direction: column;
  margin-top:10px;
  justify-content: space-between;
  padding: 4rem 2rem !important;
}

.apply-button h5 {
  font-size: 1.4rem;
}

.apply-button .ctaButton {
  font-size:1.5em;
  font-weight: bold;
  border-radius: 5rem;
}

@media (max-width: 991px){
  .apply-button h5 {
    font-size: 1rem;
  }
  .apply-button .ctaButton {
    font-size: 1rem;
  }
}

@media (max-width: 767px){
  .apply-button h5 {
    font-size: 1.4rem;
  }
  .apply-button p {
    color:#17174C;
    font-size: 12px;
  }
  .apply-button .ctaButton {
    font-size:1.4rem;
  }
}

@media (max-width: 420px){
  .apply-button {
    width: 100% !important;
  }
  .apply-button h5 {
    font-size: 1em;
  }
  .apply-button .ctaButton {
    font-size: 1rem;
    padding:0.75rem !important;
    margin:1rem 0 0 0 !important;
  }
}

</style>
